<template>
  <section>
    <goat-monitoring-add-new
      :key="keyGoatMonitoringAddNew"
      :is-add-new-goat-sidebar-active.sync="isAddNewGoatSidebarActive"
      @refetch-data="refetchData"
    />

    <b-row class="match-height">
    <b-col lg="5" md="6" sm="12" class="mb-1">
      <b-row class="d-block ml-1 mr-1"
      >
        <goat-profile 
        :key="keyGoatProfile"
        :is-add-new-goat-sidebar-active.sync="isAddNewGoatSidebarActive"/>
      </b-row>
    </b-col>  
    
    <b-col lg="7" md="6" sm="12">
      <b-row :class="rawMilkHistory.length > 0 ? 'd-block ml-1 mr-1' : 'd-none'">
        <table-goat-dynamic :title="tableTitleSusu" :items="rawMilkHistory"/>
      </b-row>
      <b-row :class="matingHistory.length > 0 ? 'd-block ml-1 mr-1' : 'd-none'">
        <table-goat-dynamic :title="tableTitleKawin" :items="matingHistory"/>
      </b-row>
      <b-row :class="giveBirthHistory.length > 0 ? 'd-block ml-1 mr-1' : 'd-none'">
        <table-goat-dynamic :title="tableTitleMelahirkan" :items="giveBirthHistory"/>
      </b-row>
      <b-row :class="'d-block ml-1 mr-1'">
        <goat-carousel :key="keyGoatCarousel"/>
      </b-row>
    </b-col>
  </b-row>      

    <b-row class="d-block ml-1 mr-1">
      <table-monitoring :key="keyTableMonitoring" ref="refTableMonitoring"/>
    </b-row>
  </section>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'

const GoatProfile = () =>  import('./GoatProfile.vue')
const GoatCarousel = () => import('./GoatCarousel.vue')
const TableGoatDynamic = () => import('./TableGoatDynamic.vue')
const TableMonitoring = () => import('./TableMonitoring.vue')

const GoatMonitoringAddNew = () => import('./GoatMonitoringAddNew.vue') 

import { ref, onUnmounted } from '@vue/composition-api'
import store from '@/store'


import monitoringStoreModule from './monitoringStoreModule'

export default {
  components: {
   
    BRow,
    BCol,
   
    
    GoatProfile,
    TableGoatDynamic,
    TableMonitoring,
    GoatCarousel,

    GoatMonitoringAddNew,
  },
  data() {
    return {
      rawMilkHistory: [],
      matingHistory: [],
      giveBirthHistory: [],
      goatData: [],
      tableTitleSusu: 'History Produksi Susu',
      tableTitleKawin: 'History Perkawinan',
      tableTitleMelahirkan: 'History Melahirkan',
      fieldsSusu: {
        type: Array,
        default: () => [ 'no', 'volume', 'kondisi_susu', 'waktu_perah']
      },
      fieldsLahiran: {
        type: Array,
        default: () => [ 'id', 'nama_kambing', 'kandang', 'HPL', 'Hasil']
      },
    }
  },
  setup(){
    const MONITORING_GOAT_APP_STORE_MODULE_NAME = 'appMonitoringGoat'

    // Register module
    if (!store.hasModule(MONITORING_GOAT_APP_STORE_MODULE_NAME)) store.registerModule(MONITORING_GOAT_APP_STORE_MODULE_NAME, monitoringStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(MONITORING_GOAT_APP_STORE_MODULE_NAME)) store.unregisterModule(MONITORING_GOAT_APP_STORE_MODULE_NAME)
    })

    const isAddNewGoatSidebarActive = ref(false)
    const refTableMonitoring = ref(null)
    const keyGoatMonitoringAddNew = ref(0)
    const keyGoatProfile = ref(0)
    const keyGoatCarousel = ref(0)
    const keyTableMonitoring = ref(0)

    const refetchData = () => {
      keyGoatProfile.value += 1
      keyGoatMonitoringAddNew.value += 1
      keyGoatCarousel.value += 1
      keyTableMonitoring.value += 1
    }

    return {
      isAddNewGoatSidebarActive, 
      refetchData, 
      refTableMonitoring,
      keyGoatMonitoringAddNew,
      keyGoatProfile,
      keyGoatCarousel,
      keyTableMonitoring,
    }
  }, 
  mounted() {

    this.$http.get('/animals/' + this.$route.params.id + '/raw-milk-history' ).then(response => {
      const goatRawMilks = response.data.data
      
      let noRawMilks = 1;
      goatRawMilks.forEach(goat => {
        this.tableTitleSusu = 'History Produksi Susu ' + goat.nama_kambing
        this.rawMilkHistory.push(
          {
            no: noRawMilks,
            volume:  goat.volume,
            kondisi_susu: goat.kondisi_susu,
            waktu_perah: goat.waktu_perah,
          }
        )
        noRawMilks++;
      });
    })

    this.$http.get('/animals/' + this.$route.params.id + '/mating-history' ).then(response => {
      const goatMatings = response.data.data
      
      let noMatings = 1;
      goatMatings.forEach(mating => {
        this.tableTitleKawin = 'History Perkawinan'
        this.matingHistory.push(
          {
            no: noMatings,
            jantan:  mating.jantan,
            betina: mating.betina,
            tempat: mating.tempat,
            tanggal: mating.tanggal,
            hasil: mating.hasil,
            hpl: mating.hpl,
          }
        )
        noMatings++;
      });
    })

    this.$http.get('/animals/' + this.$route.params.id + '/give-birth-history' ).then(response => {
      const giveBirth = response.data.data
      
      let noGiveBirth = 1;
      giveBirth.forEach(birth => {
        this.tableTitleMelahirkan = 'History Melahirkan'
        this.giveBirthHistory.push(
          {
            no: noGiveBirth,
            tempat:  birth.tempat,
            tgl:  birth.tanggal,
            jml_anak:  birth.jml_anak,
            kandang:  birth.kandang,
            proses:  birth.proses,
            vol_kolostrum: birth.jml_kolostrum,
            end_of_post: birth.end_of_post,
          }
        )
        noGiveBirth++;
      });
    })
  },
}
</script>