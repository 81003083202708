import axios from '@axios'
import router from '@/router'

export default {
  namespaced: true,
  state: {
    goatData: {},
  },
  getters: {
    getGoatData (state) {
      return state.goatData
    }
  },
  mutations: {
    SET_GOAT_DATA (state, goatData) {
      state.goatData = goatData
    }
  },
  actions: {
    async fetchAnimal({commit, dispatch},ctx, queryParams) {
      commit('SET_GOAT_DATA', dispatch('getGoatData'))
    },
    getGoatData(ctx, goatData) {
      return new Promise((resolve, reject) => {
        axios
          .get(`animals/${router.currentRoute.params.id}/detail`)
          .then(response => resolve(response.data.data))
          .catch(error => reject(error))
      })
    },
    updateMonitoring(ctx, goatData) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/animals/${router.currentRoute.params.id}/monitoring`, goatData, {
            headers: {
              'Content-type': 'multipart/form-data'
            }
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    
  },
}
